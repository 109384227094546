:root {
  --palette-common-black: #13161c;
  --palette-common-white: #fff;
  --palette-common-gray: #8e8e8e;

  --palette-primary-main: #3822a3;
  --palette-primary-light: #ae9bf8;
  --palette-primary-dark: #2c1076;

  --palette-secondary-main: #e87f38;
  --palette-secondary-light: #ec985f;
  --palette-secondary-dark: #a25827;

  --palette-error-main: #ff0000;
  --palette-error-dark: #e80d0d;

  --palette-success-main: #4caf50;

  --palette-text-primary: #1c1b1f;
  --palette-text-secondary: #696969;

  --palette-background-default: #f7f8f6;
  --palette-background-paper: #fff;

  --palette-info-main: #2196f3;
  --palette-info-contrastText: #fff;

  --palette-league-best: #008a00;
  --palette-league-good: #7dc97d;
  --palette-league-bad: #e77e3b;
  --palette-league-worst: #e80d0d;
}
