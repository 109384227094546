.header1Fluid {
  font-size: 68px;
  font-weight: 500;
}

.header2Fluid {
  font-size: 28px;
  font-weight: 500;
}

.header3Fluid {
  font-size: 24px;
  font-weight: 500;
}

.body1Fluid {
  font-size: 20px;
  line-height: 1.4;
}

.body2Fluid {
  font-size: 18px;
  line-height: 1.4;
}

.body3Fluid {
  font-size: 16px;
  line-height: 1.4;
}

.body4Fluid {
  font-size: 14px;
  line-height: 1.4;
}

.body5Fluid {
  font-size: 11px;
  line-height: 1.2;
}

@media (max-width: 1280px) {
  .header1Fluid {
    font-size: 56px;
  }
  .header2Fluid {
    font-size: 24px;
    font-weight: 500;
  }
  .header3Fluid {
    font-size: 20px;
  }
  .body1Fluid {
    font-size: 18px;
  }
  .body2Fluid {
    font-size: 16px;
  }
  .body3Fluid {
    font-size: 14px;
  }
  .body4Fluid {
    font-size: 13px;
  }
}

@media (max-width: 600px) {
  .header1Fluid {
    font-size: 40px;
  }
  .header2Fluid {
    font-size: 20px;
    font-weight: 500;
  }
  .header3Fluid {
    font-size: 18px;
  }
  .body1Fluid {
    font-size: 14px;
  }
  .body2Fluid {
    font-size: 14px;
  }
  .body3Fluid {
    font-size: 12px;
  }
  .body4Fluid {
    font-size: 12px;
  }
}

@media (max-width: 400px) {
  h1.header1Fluid {
    font-size: 32px;
  }
}
