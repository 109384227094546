html,
body {
  overscroll-behavior: none;
}

body {
  margin: 0;
  background-color: #f7f8f6;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

a {
  text-decoration: none;
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(160, 160, 160);
}

*::-webkit-scrollbar-track {
  background-color: #f7f8f6;
}

*::-webkit-scrollbar {
  width: 10px;
  /* overrides material-table */
  height: 10px !important;
  background-color: #f7f8f6;
}

*::-webkit-scrollbar-thumb {
  /* overrides material-table */
  border-radius: 10px !important;
  background-color: rgb(192, 192, 192) !important;
  border: 0 !important;
}

*[tabindex]:focus {
  outline: 0;
}

.MuiTableRow-root:last-child td {
  border-bottom: none !important;
}

#_hj_feedback_container > div > button {
  background-color: #4caf50 !important;
}

#_hj_feedback_container
  > div
  > button
  > div:last-child
  > div
  > span:last-child::before {
  color: #4caf50 !important;
}

.landscape-warning {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f7f8f6;
  text-align: center;
  z-index: 1000;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  color: #696969;
}

.landscape-warning-content {
  padding: 20px;
}

.grecaptcha-badge {
  visibility: hidden;
}

@media screen and (max-width: 768px) and (orientation: landscape) {
  body {
    overflow: hidden;
  }

  .landscape-warning {
    display: flex;
  }
}

@media screen and (max-width: 600px) {
  /* hides hotjar button on mobiles */
  ._hj_feedback_container {
    display: none !important;
  }
}
