.wrapper {
  padding: 48px 0;
  border-top: 1px solid #d9d9d9;
  background-color: #f7f8f6;
}

.container {
  display: flex;
  gap: 64px;
}

.stamdataLogo {
  max-width: 100%;
}

.logoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 230px;
  flex-grow: 2;
}

.textWrapper {
  flex-grow: 1;
  max-width: 100%;
  flex-shrink: 2;
}

.text {
  margin: 0;
}

.lastLine {
  margin: 20px 0 0;
  font-size: 11px;
}

.linksContainer {
  display: flex;
  flex-direction: column;
  min-width: 140px;
  font-size: 11px;
}

.companyLink {
  color: #4019aa;
  text-decoration: underline;
  font-weight: 700;
  margin: 0 5px;
}

.link {
  color: #4019aa;
  text-decoration: underline;
  font-weight: 700;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

.dialog:global(.MuiTypography-gutterBottom) {
  margin-bottom: 1.35em;
}

.dialogTitle:global(.MuiDialogTitle-root) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
}

.externalLink {
  font-weight: bold;
  color: #4019aa;
}

.cookieLink {
  cursor: pointer;
  font-weight: bold;
  color: white;
  text-decoration: underline;
}

.cookieBar {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.82);
  color: white;
  width: 100%;
  z-index: 999;
}

.cookieContainer:global(.MuiContainer-root) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 6px 12px;
  gap: 16px;
}

.footerButtonsWrapper {
  display: flex;
  gap: 16px;
}

.acceptButton:global(.MuiButtonBase-root),
.declineButton:global(.MuiButtonBase-root) {
  padding: 12px 20px;
  border-radius: 8px;
}

.acceptButton:global(.MuiButtonBase-root) {
  background-color: #2ec43e;
  padding: 12px 20px;

  &:hover {
    background-color: #259b30;
  }
}

.declineButton:global(.MuiButtonBase-root) {
  background-color: lightgray;
  color: #4d4d4d;

  &:hover {
    background-color: #a9a9a9;
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    padding: 40px 0;
  }
  .textWrapper {
    flex-grow: 1;
    margin-bottom: 32px;
  }
  .container {
    flex-wrap: wrap;
    gap: 0px;
  }
  .logoWrapper {
    max-width: 180px;
    order: 2;
  }
  .stamdataLogo {
    max-width: 100%;
  }
  .linksContainer {
    order: 3;
    margin-left: auto;
  }
  .link {
    text-wrap: wrap;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .cookieContainer:global(.MuiContainer-root) {
    flex-direction: column;
    padding-bottom: 12px;
    gap: 0px;
  }
  .footerButtonsWrapper {
    width: 100%;
  }
  .acceptButton:global(.MuiButtonBase-root),
  .declineButton:global(.MuiButtonBase-root) {
    padding: 6px 12px;
    border-radius: 8px;
    width: 100%;
    flex-grow: 1;
  }
  .bottomPadding {
    padding: 40px 0 94px;
  }
}

@media screen and (max-width: 400px) {
  .logoWrapper {
    max-width: 120px;
  }
}
