.dialog :global .MuiDialog-container .MuiPaper-root {
  box-shadow: none !important;
  max-width: 800px;
}

.dialogTitle:global.MuiDialogTitle-root {
  padding: 18px 24px 12px;
  max-width: 700px;

  line-height: 1.5;
  font-weight: 500;

  text-transform: none;
}

.dialogContent:global.MuiDialogContent-root {
  padding: 12px 24px 16px;
  line-height: 1.5;
  text-align: justify;
}

.dialogActions:global.MuiDialogActions-root {
  padding: 12px;
}

.link {
  color: #4019aa;
  margin-left: 4px;
}

.closeButton:global.MuiButtonBase-root {
  border-radius: 8px;
  padding: 12px 20px;
  box-shadow: none;
}

.closeButton:global.MuiButtonBase-root:hover {
  box-shadow: none;
}

@media screen and (max-width: 600px) {
  .dialog :global .MuiDialog-container .MuiPaper-root {
    max-width: none;
    margin: 12px;
  }
}