.searchBarInput :global .MuiInputBase-root {
  background-color: white;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 100%;
  border-width: 2px;
}

.searchBarInput :global .MuiInputBase-input {
  font-size: 18px;
}

.searchBarInput :global .MuiInputBase-root input::-webkit-search-cancel-button {
  cursor: pointer;
}

:global .MuiAutocomplete-popper .MuiAutocomplete-listbox {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  padding: 0;
}

.searchBarInput
  :global
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-width: 1px;
}

.searchBarInput :global .MuiInputAdornment-root:first-child {
  margin-left: 12px;
  font-size: 18px;
}

.searchBarInput :global .MuiInputAdornment-root .MuiSvgIcon-root {
  font-size: 28px;
}

.searchBarHeaderInput :global .MuiInputBase-root {
  background-color: #4c4c4c;
  border-radius: 5px;
  height: 100%;
  color: #d9d9d9;
  margin: 0;
}

.searchBarHeaderInput :global .MuiInputAdornment-root .MuiSvgIcon-root {
  margin-right: 4px;
}

.searchBarHeaderInput :global .MuiInputBase-input {
  background-color: #4c4c4c;
  border-radius: 5px;
}

.searchBarHeaderInput :global .MuiInputBase-root.MuiFilledInput-root:hover {
  background-color: #4c4c4c;
  width: 100%;
}

.searchBarHeaderWrapper {
  display: flex;
  margin-left: 1rem;
  height: 50px;
  flex: 0 1 400px;
}

.searchBarWrapper {
  display: flex;
  height: 60px;
  width: min(670px, 100%);
}

button.button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 100%;
  width: 130px;
  font-size: 16px;
}

.optionsItem {
  display: flex;
  flex-direction: column;
  padding: 12px 16px 4px;
  gap: 2px;
  border-top: 1px solid #dbdbdb;
}

.optionsItem:first-child {
  border-top: none;
}

.optionsItem:last-child {
  border-bottom: 1px solid #dbdbdb;
}

.optionsItem:global.Mui-focused {
  cursor: pointer;
  background-color: #f5f5f5;
}

.optionsItem:global.Mui-focusVisible {
  background-color: #f5f5f5;
}

.optionTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.1;
  display: flex;
  align-items: baseline;
}

.optionSubtitle {
  font-size: 12px;
  color: var(--palette-common-gray);
  font-weight: 400;
}

@media screen and (max-width: 960px) {
  .searchBarWrapper {
    width: 100%;
  }

  :global .MuiAutocomplete-popper.isDrawerSearch {
    width: 100% !important;
  }

  :global .MuiAutocomplete-popper.isDrawerSearch .MuiPaper-root {
    box-shadow: none;
    margin-top: 8px;
    border-radius: 0;
  }

  :global .MuiAutocomplete-popper.isDrawerSearch .MuiAutocomplete-listbox {
    max-height: max-content;
  }

  .optionSubtitle {
    line-height: normal;
    padding-top: 5px;
  }
}

@media screen and (max-width: 600px) {
  .searchBarWrapper {
    display: flex;
    height: 46px;
    width: 100%;
  }
  .searchBarWrapper :global .MuiAutocomplete-root {
    width: 100%;
  }
  .searchBarInput :global .MuiInputBase-root {
    border-radius: 4px;
  }
  .searchBarInput :global .MuiInputBase-input {
    font-size: 14px;
  }
  .searchBarInput :global .MuiInputAdornment-root:first-child {
    margin-left: 4px;
    font-size: 14px;
  }
  .searchBarInput :global .MuiInputAdornment-root .MuiSvgIcon-root {
    font-size: 22px;
  }
  button.button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 100%;
    width: 80px;
    font-size: 13px;
  }
  .searchBarWrapper :global .MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0 9px;
  }
  .searchBarWrapper
    :global
    .MuiAutocomplete-root
    .MuiOutlinedInput-root
    .MuiAutocomplete-input {
    padding: 7.5px 0;
  }
  .optionsItem {
    padding: 13px 14px 13px;
  }
  .optionTitle {
    font-size: 14px;
  }
  :global .MuiAutocomplete-popper .MuiAutocomplete-noOptions {
    font-size: 14px;
    padding: 8px 14px;
  }
}
