.drawer :global .MuiModal-backdrop {
  backdrop-filter: blur(1.5px);
  -webkit-backdrop-filter: blur(1.5px);
}

.drawer :global .MuiPaper-root {
  border-radius: 0 !important;
  border: none;
  box-shadow: none !important;
}

.drawerHeader {
  background-color: #090b0e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}
