@import url('themes/palette.module.css');

.logo {
  height: 2.8rem;
  margin-top: 0.2rem;
}

.header {
  background-color: var(--palette-common-black);
  color: var(--palette-common-white);
  position: sticky;
  top: 0;

  z-index: 999;
}

.mainMenu__box {
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.mainMenu__link {
  text-transform: uppercase;
  border-radius: 8px;
  color: var(--palette-common-white);
  font-weight: 500;
  line-height: 20px;
  font-size: 0.8125rem;
  padding: 12px;
  text-align: center;
}

.rightToolbar,
.leftToolbar {
  display: flex;
  padding: 0;
  align-items: center;
}

.leftToolbar {
  flex-grow: 1;
  margin-right: 12px;
}

.headerToolbar > * {
  display: flex;
  justify-content: space-between;
}

.esgButton:global(.MuiButtonBase-root) {
  margin-left: 16px;
  padding: 12px 24px;
  background-color: #2ec43e;
  border-radius: 8px;

  &:hover {
    background-color: #259b30;
  }
}

.link {
  color: white;
}

@media screen and (max-width: 1280px) {
  .headerToolbar :global .MuiToolbar-root.MuiToolbar-gutters {
    padding: 0 24px;
  }
  .logo {
    height: 48px;
  }
}

@media screen and (max-width: 960px) {
  .headerToolbar :global .MuiToolbar-root.MuiToolbar-gutters {
    padding: 0 24px;
    min-height: auto;
    height: 56px;
  }
  .logo {
    height: 32px;
    margin-top: 0.2rem;
  }
}

@media screen and (max-width: 600px) {
  .headerToolbar :global .MuiToolbar-root.MuiToolbar-gutters {
    padding: 0 16px;
  }
}
