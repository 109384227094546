.drawer :global .MuiModal-backdrop {
  backdrop-filter: blur(1.5px);
  -webkit-backdrop-filter: blur(1.5px);
}

.drawer :global .MuiPaper-root {
  border-radius: 0 !important;
  border: none;
  box-shadow: none !important;
}

.drawerHeader {
  background-color: #090b0e;
  height: 56px;
  display: flex;
  justify-content: flex-end;
  padding: 0 8px;
}

.menuItem:global.MuiListItem-root {
  border-bottom: 1px solid #d9d9d9;
}

.menuList:global.MuiList-root {
  padding: 0;
}

.link {
  text-transform: capitalize;
  text-align: center;
  color: #5f5f5f;
  font-weight: 400;
  font-size: 16px;
  padding: 8px 0;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.link :first-child {
  display: flex;
  align-items: center;
  gap: 10px;
}
