.indicator {
  width: 12px;
  height: 12px;
  margin-right: 8px;
}

@media screen and (max-width: 600px) {
  .indicator {
    width: 10px;
    height: 10px;
    margin-right: 6px;
  }
}
